import React from "react";
import {
  MetaSetter,
  RequestLoaderTrigger,
} from "components/secondaryComponents";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./GiftCards.module.scss";
import icons from "assets/icons";
import useGiftCards from "./useGiftCards";
import { LoaderHelper } from "components/secondaryComponents";
import { Link } from "react-router-dom";

const GiftCards = () => {
  const { isLoading, data, brand_logo_url } = useGiftCards();

  return (
    <MetaSetter
      title="Lumi Merchant Loyalty | Dashboard - Gift cards"
      description="Claim your rewards as a gift card"
    >
      <RequestLoaderTrigger>
        <AnimatePresence>
          <motion.div
            initial={{ x: -50 }}
            animate={{ x: 0 }}
            exit={{ x: 50 }}
            className={styles.giftCards}
          >
            <LoaderHelper isLoading={isLoading} classNames="mt-5">
              <>
                {data.length > 0 ? (
                  <div className={styles.cardsContainer}>
                    {data?.map((item) => (
                      <Link
                        to={`/app/gift-cards/${item.id}`}
                        className={styles.cardItem}
                        key={item.id}
                      >
                        <div className={styles.cardItem__card}>
                          <div className={styles.brand}>
                            <img src={brand_logo_url} alt="brand" />
                          </div>
                          <p className="dark--text">{item.name}</p>
                        </div>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ gap: "15px" }}
                        >
                          {/* <h6
                            className={`smallest-text-size mb-0 ${styles.amount}`}
                          >
                            {formatMoney(item.amount_in_naira)}
                          </h6> */}
                          {/* <div className={styles.points}>
                            <span>{formatPoints(item.amount_in_points)}</span>
                          </div> */}
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <div className="text-center mt-5">
                    <img
                      width="211px"
                      src={icons.giftCardEmpty}
                      alt="emptyState"
                    />
                    <h5>No Gift Cards Available yet</h5>
                  </div>
                )}
              </>
            </LoaderHelper>
          </motion.div>
        </AnimatePresence>
      </RequestLoaderTrigger>
    </MetaSetter>
  );
};
export default GiftCards;
